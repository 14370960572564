<div
  class="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-fixed navbar-light navbar-shadow menu-border navbar-brand-center  {{transparentBGClass}}"
  role="navigation" data-menu="menu-wrapper" data-nav="brand-center"
  [ngClass]="{'navbar-sticky': menuPosition === 'Top' && config?.layout.navbar.type === 'Static' , 'navbar-fixed': menuPosition === 'Top' && config?.layout.navbar.type === 'Fixed'}">
  <!-- Horizontal menu content-->
  <div class="navbar-container main-menu-content center-layout" data-menu="menu-container">
    <ul appTopMenuDropdown class="navigation-main nav navbar-nav" id="main-menu-navigation" data-menu="menu-navigation">
      <!-- First level menu -->
      <li appTopMenulink *ngFor="let menuItem of menuItems" [parent]="menuItem.name" level="{{level + 1}}"
        class="dropdown nav-item"
        [routerLinkActive]="menuItem.childMenus?.length > 0 ? '':'active'" [routerLinkActiveOptions]="{exact: true}">

        <a appTopMenuAnchorToggle *ngIf="menuItem.childMenus?.length > 0" data-toggle="dropdown"
          class="dropdown-toggle nav-link d-flex align-items-center">
          <!-- <i [ngClass]="[menuItem.icon]"></i> -->
          <span>{{menuItem.name | translate }}</span>
        </a>
        <a appTopMenuAnchorToggle routerLink="{{menuItem.route}}" *ngIf="menuItem.childMenus?.length === 0"
          data-toggle="dropdown" class="nav-link d-flex align-items-center">
          <!-- <i [ngClass]="[menuItem.icon]"></i> -->
          <span>{{menuItem.name | translate }}</span>
        </a>
        <!-- <a [href]="[menuItem.route]" target="_blank" *ngIf="menuItem.isExternalLink" data-toggle="dropdown"
          class="nav-link d-flex align-items-center">
          <i [ngClass]="[menuItem.icon]"></i>
          <span>{{menuItem.name | translate }}</span>
        </a> -->

        <!-- Second level menu -->
        <ul appTopMenuDropdown class="dropdown-menu" *ngIf="menuItem.childMenus?.length > 0">
          <li appTopMenulink (click)="TopMenuDirectives.onMouseOut($event)"
            *ngFor="let menuSubItem of menuItem.childMenus" [parent]="menuSubItem.name" level="{{level + 2}}"
            [routerLinkActive]="menuSubItem.childMenus?.length > 0 ? '':'active'"
           >

            <a appTopMenuAnchorToggle *ngIf="menuSubItem.childMenus?.length > 0 "
              [ngClass]="'dropdown-toggle dropdown-item d-flex align-items-center'">
              <!-- <i [ngClass]="[menuSubItem.icon]"></i> -->
              <span>{{menuSubItem.name | translate }}</span>
            </a>
            <a appTopMenuAnchorToggle routerLink="{{menuSubItem.route}}" *ngIf="menuSubItem.childMenus?.length === 0 "
              [ngClass]="'dropdown-item d-flex align-items-center'">
              <!-- <i [ngClass]="[menuSubItem.icon]"></i> -->
              <span >{{menuSubItem.name | translate }}</span>
            </a>
            <!-- <a [href]="[menuSubItem.route]" *ngIf="menuSubItem.isExternalLink"
              [ngClass]="'dropdown-item d-flex align-items-center'">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span>{{menuSubItem.name | translate }}</span>
            </a> -->

          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
