import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  constructor(private http: HttpClient,public _firebaseAuth: AngularFireAuth, public router: Router) {

    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
        }
        else {
          this.userDetails = null;
        }
      }
    );

  }

  forgotPassword(json){
    return this.http.post(environment.baseUrl + 'User/ChangePassword', json, { headers: this.createHeader(), responseType: 'text' }).toPromise();


  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

    //uncomment above firebase auth code and remove this temp code
    // return new Promise((resolve, reject) => {
      return this.http.post(environment.baseUrl + 'token/auth', {username:email,password:password,grant_type: "password"}).toPromise();
    // });

  }

  getUSerPermissionService() {
    //your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

    //uncomment above firebase auth code and remove this temp code
    // return new Promise((resolve, reject) => {

      return this.http.get(environment.baseUrl + 'Permission/GetAllUserPermissions?userId=' + localStorage.getItem('userID'),{ headers: this.createHeader()}).toPromise();
    // });

  }



  logout() {
    this._firebaseAuth.signOut();
    this.router.navigate(['YOUR_LOGOUT_URL']);
  }

  isAuthenticated() {
    return true;
  }
  createHeader() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let token = localStorage.getItem('token');
    if (token) {
      headers=  headers.append('Authorization', 'Bearer ' + token);
    }
    return headers;
  }
}
