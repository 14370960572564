<!-- Sidebar Header starts -->
<div class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'">
  <div class="logo clearfix">
    <a [routerLink]="['/']" class="logo-text float-left">
      <div class="logo-img">
        <img [src]="logoUrl" alt="Apex logo"/>
      </div>
      <span class="text align-middle">APEX</span>
    </a>
    <a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;"
      (click)="toggleSidebar()">
      <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>
    </a>
    <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="CloseSidebar()">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content main-menu-content" [perfectScrollbar]  [disabled]="!perfectScrollbarEnable">
  <div class="nav-container">
    <ul class="navigation" appSidebarDropdown>
      <!-- First level menu -->
      <!-- {{menuItems | json}} -->
      <li appSidebarlink *ngFor="let menuItem of menuItems" [parent]="menuItem.name" [path]="menuItem.route" level="{{level + 1}}" [hasSub]="menuItem.childMenus?.length > 0 ? true: false"
        [ngClass]="{'has-sub': menuItem.childMenus?.length > 0 ? true: false}"
        [routerLinkActive]="menuItem.childMenus?.length > 0 ? '':'active'" [routerLinkActiveOptions]="{exact: false}">
        <a appSidebarAnchorToggle *ngIf="menuItem.childMenus?.length > 0 && !menuItem.isExternalLink">
          <!-- <i [ngClass]="[menuItem.icon]"></i> -->
          <span class="menu-title">{{menuItem.name | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " >{{menuItem.badge}}</span>
        </a>
        <a appSidebarAnchorToggle routerLink="{{menuItem.route}}"
          *ngIf="menuItem.childMenus?.length === 0 && !menuItem.isExternalLink">
          <!-- <i [ngClass]="[menuItem.icon]"></i> -->
          <span class="menu-title">{{menuItem.name | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " >{{menuItem.badge}}</span>
        </a>
        <!-- <a [href]="[menuItem.route]" target="_blank" *ngIf="menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.name | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " >{{menuItem.badge}}</span>
        </a> -->
         <!-- Second level menu -->
        <ul appSidebarDropdown class="menu-content" *ngIf="menuItem.childMenus?.length > 0 ">
          <li appSidebarlink *ngFor="let menuSubItem of menuItem.childMenus" [parent]="menuSubItem.name" [hasSub]="menuSubItem.childMenus?.length > 0 ? true: false"
          [path]="menuSubItem.route" level="{{level + 2}}" [ngClass]="{'has-sub': menuSubItem.childMenus?.length > 0 ? true: false}"
          [routerLinkActive]="menuSubItem.childMenus?.length > 0 ? '':'active'">
            <a appSidebarAnchorToggle *ngIf="menuSubItem.childMenus?.length > 0 && !menuSubItem.isExternalLink">
              <!-- <i [ngClass]="[menuSubItem.icon]"></i> -->
              <span class="menu-title">{{menuSubItem.name | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " >{{menuSubItem.badge}}</span>
            </a>
            <a appSidebarAnchorToggle routerLink="{{menuSubItem.route}}"
              *ngIf="menuSubItem.childMenus?.length === 0 && !menuSubItem.isExternalLink">
              <!-- <i [ngClass]="[menuSubItem.icon]"></i> -->
              <span class="menu-title">{{menuSubItem.name | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " >{{menuSubItem.badge}}</span>
            </a>
          
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
