<!-- *NAVBAR* starts -->
<!-- ye code hide karna h condition k base per -->
<!-- *ngIf="showMenu" -->
<app-navbar  (seachTextEmpty)="checkNavbarSeachTextEmpty($event)"></app-navbar>
<!-- *NAVBAR* ends -->
<!-- ye code hide karna h condition k base per -->
<div class="wrapper"  [ngClass]="{'show-overlay': !isNavbarSeachTextEmpty}" (click)="onWrapperClick();" (window:resize)="onResize($event)">

  <!-- *TOP* Menu starts -->
  <!-- ye code hide karna h condition k base per -->
  <!-- *ngIf="menuPosition === 'Top' && !displayOverlayMenu && menuData.length != 0 && showMenu" -->
  <app-horizontal-menu  appTopMenu *ngIf="menuPosition === 'Top' && !displayOverlayMenu && menuData.length != 0 " [menuData] = "menuData"></app-horizontal-menu>
  <!-- *TOP* Menu ends -->

  <!-- *SIDE* Menu starts -->
  <!-- ye code hide karna h condition k base per -->
  <!-- *ngIf="(menuPosition === 'Side' || displayOverlayMenu) && showMenu" -->
  <div appSidebar *ngIf="(menuPosition === 'Side' || displayOverlayMenu) "
    [ngClass]="{'main-menu': menuPosition === 'Side' || displayOverlayMenu, 'menu-fixed': menuPosition === 'Side' || displayOverlayMenu, 'menu-native-scroll': !perfectScrollbarEnable }" class="app-sidebar"
    (mouseenter)="sidebarMouseenter($event)"  data-active-color="white"
    [attr.data-background-color]="config?.layout.variant === 'Transparent' ? 'black': bgColor"
    [attr.data-image]="bgImage">
    <app-sidebar *ngIf="menuData.length != 0"  [menuData] = "menuData"></app-sidebar>
    <div class="sidebar-background" [ngStyle]="{'background-image': 'url(' + bgImage + ')'}"
      *ngIf="config?.layout.sidebar.backgroundImage"></div>
  </div>
  <!-- *SIDE* Menu ends -->

  <!-- <div class="{{ showMenu ?  'main-panel' : ''}}">
    <div class="{{ showMenu ? 'main-content' : ''}}">
      <div *ngIf="showMenu" class="content-overlay"></div>
      <div class="{{ showMenu ? 'content-wrapper'  : ''}}">
        <router-outlet></router-outlet>
      </div>
    </div> -->

    <div class="main-panel">
      <div class="main-content">
        <div  class="content-overlay"></div>
        <div class="content-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div> 
    <!-- FOOTER -->

<!-- ye code hide karna h condition k base per -->

    <!-- <app-footer *ngIf="showMenu"></app-footer> -->
    <!-- Scroll to top button -->
    <button class="btn btn-primary scroll-top" type="button" *ngIf="isScrollTopVisible" (click)="scrollToTop()"><i class="ft-arrow-up"></i></button>
  </div>

  <!-- *NOTIFICATION-SIDEBAR* starts -->
  <!-- *ngIf="showMenu" -->
  <app-notification-sidebar></app-notification-sidebar>
  <!-- *NOTIFICATION-SIDEBAR* ends -->

  <!-- *CUSTOMIZER* starts -->
  <!-- *ngIf="showMenu" -->
  <app-customizer ></app-customizer>
  <!-- *CUSTOMIZER* ends -->

  <!-- <div class="buy-now">
    Buy now button
    <a href="https://1.envato.market/apex_admin" target="_blank" class="btn btn-danger">Buy Now</a>
  </div> -->
  <!-- *ngIf="showMenu" -->
  <div class="sidenav-overlay" (click)="onOutsideClick($event);"
    [ngClass]="{'d-none': displayOverlayMenu && hideSidebar && !overlayContent , 'd-block': displayOverlayMenu && !hideSidebar && overlayContent && innerWidth < 1200}"></div>
  <div class="drag-target"></div>
</div>

