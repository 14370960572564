import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'utils',
    loadChildren: () => import('../../utilities/utilities.module').then(moduleRoute => moduleRoute.UtilitiesModule)
  },
  {
    path: 'report',
    loadChildren: () => import('../../reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'result',
    loadChildren: () => import('../../result/result.module').then(m => m.ResultModule)
  },
  {
    path: 'master',
    loadChildren: () => import('../../master/master.module').then(m => m.MasterModule)
  },
 {
    path: 'transection',
    loadChildren: () => import('../../transection/transection.module').then(m => m.TransectionModule)
  },
  {
     path: 'voucher',
     loadChildren: () => import('../../voucher/voucher.module').then(m => m.VoucherModule)
   }, {
    path: 'admin',
    loadChildren: () => import('../../admin-report/admin.module').then(m => m.AdminModule)
  }, {
    path: 'track',
    loadChildren: () => import('../../track-report/track.module').then(m => m.TrackModule)
  },      
  {
    path: 'datatables',
    loadChildren: () => import('../../data-tables/data-tables.module').then(m => m.DataTablesModule)
  },
  {
    path: 'uikit',
    loadChildren: () => import('../../ui-kit/ui-kit.module').then(m => m.UIKitModule)
  },
  {
    path: 'components',
    loadChildren: () => import('../../components/ui-components.module').then(m => m.UIComponentsModule)
  }    
];
