import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2
} from "@angular/core";
import { ConfigService } from "../services/config.service";
import { Subscription } from "rxjs";
import { CustomizerService } from '../services/customizer.service';

@Component({
  selector: "app-customizer",
  templateUrl: "./customizer.component.html",
  styleUrls: ["./customizer.component.scss"]
})
export class CustomizerComponent implements OnInit, OnDestroy {
  @ViewChild("customizer") customizer: ElementRef;

  size: string;
  isBgImageDisplay: boolean = true;
  isOpen = true;
  public config: any = {};
  layoutSub: Subscription;

  constructor(
    private renderer: Renderer2,
    private configService: ConfigService,
    public customizerService: CustomizerService,
  ) {

    this.config = this.configService.templateConf;
    this.isOpen = !this.config.layout.customizer.hidden;

    if (this.config.layout.sidebar.size) {
      this.size = this.config.layout.sidebar.size;
    }
  }

  @Output() directionEvent = new EventEmitter<Object>();

  ngOnInit() {

  }

  todayDate(){
    let date = new Date();
    let month = this.pad2(date.getMonth() + 1);//months (0-11)
    let day = this.pad2(date.getDate());//day (1-31)
    let year = date.getFullYear();
    let todayDate = year + "-" + month + "-" + day;
    return todayDate

  }
  todayShiftDate(date){

    let month = this.pad2(date.getMonth() + 1);//months (0-11)
    let day = this.pad2(date.getDate());//day (1-31)
    let year = date.getFullYear();
    let todayDate = year + "-" + month + "-" + day;
    return todayDate

  }
  checkValidNumber(){
    let validArray= ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59','60','']
  }
  pad2(n) {
    return (n < 10 ? '0' : '') + n;
  }
  getSumOfSameKey(arr: any) {
    let obj = arr.reduce((acc: any, ele: any) => {
      let [key, value] = Object.entries(ele)[0];

      acc[key] = acc[key] ? acc[key] + value : value;
      return acc;
    }, {});

    return Object.entries(obj).map(([key, value]) => ({ [key]: value }));
  }


  changeIntoMainJson(changeArray) {
    let a = []
    for (let index = 0; index <  changeArray['number'].length; index++) {
      for (let indexj = 0; indexj < changeArray['number'][index]['values'].length; indexj++) {
        a.push(changeArray['number'][index]['values'][indexj])

      }

    }
    for (let index = 0; index <  changeArray['ander']?.length; index++) {
      a.push(changeArray['ander'][index])
    }
    for (let index = 0; index <  changeArray['bahar']?.length; index++) {
      a.push(changeArray['bahar'][index])
    }

    console.log(a)

    return a

  }
  getEmptyJantri(){
    return [{
      number:[{values:[{1:null,val:1},{2:null,val:2},{3:null,val:3},{4:null,val:4},{5:null,val:5},{6:null,val:6},{7:null,val:7},{8:null,val:8},{9:null,val:9},{10:null,val:10}]},
      {values:[{11:null,val:11},{12:null,val:12},{13:null,val:13},{14:null,val:14},{15:null,val:15},{16:null,val:16},{17:null,val:17},{18:null,val:18},{19:null,val:19},{20:null,val:20}]},
      {values:[{21:null,val:21},{22:null,val:22},{23:null,val:23},{24:null,val:24},{25:null,val:25},{26:null,val:26},{27:null,val:27},{28:null,val:28},{29:null,val:29},{30:null,val:30}]},
      {values:[{31:null,val:31},{32:null,val:32},{33:null,val:33},{34:null,val:34},{35:null,val:35},{36:null,val:36},{37:null,val:37},{38:null,val:38},{39:null,val:39},{40:null,val:40}]},
      {values:[{41:null,val:41},{42:null,val:42},{43:null,val:43},{44:null,val:44},{45:null,val:45},{46:null,val:46},{47:null,val:47},{48:null,val:48},{49:null,val:49},{50:null,val:50}]},
      {values:[{51:null,val:51},{52:null,val:52},{53:null,val:53},{54:null,val:54},{55:null,val:55},{56:null,val:56},{57:null,val:57},{58:null,val:58},{59:null,val:59},{60:null,val:60}]},
      {values:[{61:null,val:61},{62:null,val:62},{63:null,val:63},{64:null,val:64},{65:null,val:65},{66:null,val:66},{67:null,val:67},{68:null,val:68},{69:null,val:69},{70:null,val:70}]},
      {values:[{71:null,val:71},{72:null,val:72},{73:null,val:73},{74:null,val:74},{75:null,val:75},{76:null,val:76},{77:null,val:77},{78:null,val:78},{79:null,val:79},{80:null,val:80}]},
      {values:[{81:null,val:81},{82:null,val:82},{83:null,val:83},{84:null,val:84},{85:null,val:85},{86:null,val:86},{87:null,val:87},{88:null,val:88},{89:null,val:89},{90:null,val:90}]},
      {values:[{91:null,val:91},{92:null,val:92},{93:null,val:93},{94:null,val:94},{95:null,val:95},{96:null,val:96},{97:null,val:97},{98:null,val:98},{99:null,val:99},{100:null,val:100}]}],
      bahar:[{B1:null,val:'B1'},{B2:null,val:'B2'},{B3:null,val:'B3'},{B4:null,val:'B4'},{B5:null,val:'B5'},{B6:null,val:'B6'},{B7:null,val:'B7'},{B8:null,val:'B8'},{B9:null,val:'B9'},{B10:null,val:'B10'}],
      ander:[{A1:null,val:'A1'},{A2:null,val:'A2'},{A3:null,val:'A3'},{A4:null,val:'A4'},{A5:null,val:'A5'},{A6:null,val:'A6'},{A7:null,val:'A7'},{A8:null,val:'A8'},{A9:null,val:'A9'},{A10:null,val:'A10'}],

    }

     ]
  }

  changeSidebarWidth(value) {
    this.size = value;
    this.customizerService.changeSidebarWidth(value);
  }

  toggleCustomizer() {
    if (this.isOpen) {
      this.renderer.removeClass(this.customizer.nativeElement, "open");
      this.isOpen = false;
    } else {
      this.renderer.addClass(this.customizer.nativeElement, "open");
      this.isOpen = true;
    }
  }

  closeCustomizer() {
    this.renderer.removeClass(this.customizer.nativeElement, "open");
    this.isOpen = false;
  }

    bgImageDisplay(e) {
    if (e.target.checked) {
      this.isBgImageDisplay = true;
    } else {
      this.isBgImageDisplay = false;
    }
    //emit event to FUll Layout
    this.customizerService.bgImageDisplay(e);
  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }
  getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
}




  setDate(value, shiftDate, type) {
    console.log('value is', value);

   let tempDate =  shiftDate.split('-');
   if(type == 'DD') {
    const maxDate = this.getDaysInMonth(parseInt(tempDate[1]), parseInt(tempDate[0]))
     if(parseInt(value) < 10){
      if(this.checkISstartWithZero(value)){
        value =  value;
      }else{
        value = '0' + value;
      }

     }
    if (parseInt(value) > maxDate) {
       tempDate[2] = maxDate;
     } else{
      tempDate[2] = value ;
     }
   } else if(type == 'MM') {
    if(parseInt(value) < 10){
      if(this.checkISstartWithZero(value)){
        value =  value;
      }else{
        value = '0' + value;
      }

     }
     if (parseInt(value) > 12) {
       tempDate[1] = '12';
     } else{
      tempDate[1] = value ;
     }
     const maxDate = this.getDaysInMonth(parseInt(tempDate[1]), parseInt(tempDate[0]))
     if (parseInt(tempDate[2]) > maxDate) {
       tempDate[2] = maxDate;
     }
   } else if(type == 'YY') {
      tempDate[0] = value ;
      const maxDate = this.getDaysInMonth(parseInt(tempDate[1]), parseInt(tempDate[0]))
      if(parseInt(value) < 10){
        value = '0' + value;
       }
     if (parseInt(tempDate[2]) > maxDate) {
       tempDate[2] = maxDate;
     }
   }
//    let a = Number(tempDate[2])==this.getLastDayOfMonth(Number(tempDate[0]), Number(tempDate[1]))
// let b = Number(tempDate[2])
// let c = this.getLastDayOfMonth(Number(tempDate[0]), Number(tempDate[1]))
// if(  Number(tempDate[2])==this.getLastDayOfMonth(Number(tempDate[0]), Number(tempDate[1]))){
//   let tempLastDate =tempDate
//   tempDate[2] = Number(tempLastDate[2])-1
//   shiftDate = tempDate.join("-");
// }else{
  shiftDate = tempDate.join("-");
// }

  
   return shiftDate
   }
   checkISstartWithZero(value:any){
    if(value.charAt(0)=="0"){
      return true
    }else{
      return  false
    }

   }
   zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }
   getProperFormatedDate(shiftDate, type) {
    if(shiftDate){
     let tempDate =  shiftDate.split('-');
   if(type == 'DD') {
     return tempDate[2];
   } else if(type == 'MM') {
     return tempDate[1];
   } else if(type == 'YY') {
     return tempDate[0];
   }
  }
   }

   getDaysInMonth(month,year) {
    return new Date(year, month, 0).getDate();
   };

   changeStringReverce(data){
    let string = data.toString()     
if(string < 0){  
let balance = string.substring(1) + ' Cr' 
return balance
} else if(string > 0) {
  return data.toString() + ' Dr'
}else{
  return data
}
  }
  setExcellRowsForFinalBalance(rows, apicols, tableCols) {
    let tempRows = [];
    for(let i =0;i< rows?.length;i++){
      let tempObj = {};
    for(let j =0;j< apicols?.length;j++){
      if (apicols[j] === 'closing') {
        tempObj[tableCols[j]] =rows[i][apicols[j]] > 0 ? this.changeStringReverce(rows[i][apicols[j]]):  this.changeStringReverce(rows[i][apicols[j]]);;
    break;
      }else{

      tempObj[tableCols[j]] = apicols[j] == 'SR' ? i + 1 : rows[i][apicols[j]];
      }
    }
    tempRows.push(tempObj);
    }
    console.log(tempRows);
   return tempRows;
   }
   setExcellRows(rows, apicols, tableCols) {
    let tempRows = [];
    for(let i =0;i< rows?.length;i++){
      let tempObj = {};
    for(let j =0;j< apicols?.length;j++){
      tempObj[tableCols[j]] = apicols[j] == 'SR' ? i + 1 :  rows[i][apicols[j]];
    }
    tempRows.push(tempObj);
    }
    console.log(tempRows);
   return tempRows;
   }
   sumSalesByNumber(inputArray:any){
    const groupedSales = inputArray.reduce((acc, curr) => {
      const { number, sale, ...rest } = curr;
      if (acc[number]) {
        acc[number].sale += sale;
      } else {
        acc[number] = { number, sale, ...rest };
      }
      return acc;
    }, {});
    return Object.values(groupedSales);
  }
}
