import { Component, ViewContainerRef, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { filter,map } from 'rxjs/operators';
// import { ConnectionService } from 'ng-connection-service';
import { ToastrService } from 'ngx-toastr';
import { ConnectionService } from 'ng-connection-service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    isConnected: boolean;
    constructor(private router: Router,private connectionService: ConnectionService, private toastr: ToastrService,private activatedRoute: ActivatedRoute,
        private titleService: Title) {
        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            if (this.isConnected) {
              this.toastr.success('Internet is Connected.', 'Success');
            }
            else {
              this.toastr.error('Please Connect to internet.', 'Error');
            }
          })
    }

    ngOnInit() {
      if(this.isHmg31()){
      this.titleService.setTitle('HMG');
      }else{
        this.titleService.setTitle('DEMO');
      }
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));


            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
              )
              .subscribe(() => {

                var rt = this.getChild(this.activatedRoute)

                rt.data.subscribe(data => {
                  console.log(data);
                  this.titleService.setTitle(data.title)})
              })
    }
    isHmg31(): boolean {
      return window.location.href.includes('hmg31');
    }

 getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    scrollBody(direction) {
      const datatableBodyElement = document.querySelector('datatable-body');
     if (datatableBodyElement) {
        //  console.log('datatableBodyElement.scrollTop',datatableBodyElement.scrollTop);
         if(direction == 'bottom') {
        datatableBodyElement.scrollTop = datatableBodyElement.scrollTop + 50;
         } else {
           datatableBodyElement.scrollTop = datatableBodyElement.scrollTop - 50;
         }
      }
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: any) {
    // console.log(event);
     if(event['keyCode'] == 40  ) {
       this.scrollBody('bottom');
     } else if( event['keyCode'] == 38) {
       this.scrollBody('top');
     }
 }
 @HostListener("focusout", ["$event"])
 onBlur(event: any) {
  console.log('focus out',event);
  if((event.target.name == 'dd' || event.target.name == 'mm') && (event.target.value == '' || event.target.value == 0)) {
    this.toastr.warning('Please enter valid Date.');
  } else if(event.target.name == 'yy' && event.target.value.length < 4) {
    this.toastr.warning('Please enter valid Date.');
  }
 }

}
